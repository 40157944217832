import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throttleTime } from 'rxjs'
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  dynamicId: string;

  theme1 = {
    themeName: 'Theme1',
    primaryColor: '#EBFAFD',
    secondaryColor: '#25b1c0',
    thirdColor: '#25b1c01a',
    fourtFirst: "#25b1c0ad",
    fifthColor: "#25B1C03B"
  }

  theme2 = {
    themeName: 'Theme2',
    primaryColor: ' #EBF2FD',
    secondaryColor: '#256DC0DE',
    thirdColor: '#256DC02B',
    fourtFirst: "#256dc0ad",
    fifthColor: "#3D85C536"
  }

  theme3 = {
    themeName: 'Theme3',
    primaryColor: '#FFF5F5',
    secondaryColor: '#C02525DE',
    thirdColor: "#c025251a",
    fourtFirst: '#c02525ad',
    fifthColor: "#C0252521"
  }

  theme4 = {
    themeName: 'Theme4',
    primaryColor: '#F7EBFD75',
    secondaryColor: '#8825c0',
    thirdColor: '#8825C021',
    fourtFirst: '#8825c0ad',
    fifthColor: "#CEA6E54D",

  }
  constructor(private authService: AuthService) { }

  public getThemeObject = function (themeName) {
    let themeData;

    if (this.theme1.themeName == themeName) {
      themeData = this.theme1;
    }

    if (this.theme2.themeName == themeName) {
      themeData = this.theme2;
    }

    if (this.theme3.themeName == themeName) {
      themeData = this.theme3;
    }

    if (this.theme4.themeName == themeName) {
      themeData = this.theme4;
    }

    return themeData;
  }
  public setsearch = new BehaviorSubject<string>('');
  setsearch$ = this.setsearch.asObservable();

  public navigaterefresh = new BehaviorSubject<string>('');
  navigaterefresh$ = this.navigaterefresh.asObservable();

  public shareTimeZone = new BehaviorSubject<string>('');
  timeZONE$ = this.shareTimeZone.asObservable();

  public setThemeName = new BehaviorSubject<string>('');
  setThemeName$ = this.setThemeName.asObservable();

  public peep = new BehaviorSubject<boolean>(false);
  // public peep$= this.peep.asObservable();

  peep1 = new BehaviorSubject<boolean>(false);
  peep1$ = this.peep.asObservable();

  peep2 = new BehaviorSubject<boolean>(false);
  peep2$ = this.peep.asObservable();

  peep3 = new BehaviorSubject<boolean>(false);
  peep3$ = this.peep.asObservable();

  sendMessage(message: boolean) {

    this.peep.next(message)
  }

  sendMessage1(message1: boolean) {
    this.peep1.next(message1)
  }

  sendMessage2(message2: boolean) {
    this.peep2.next(message2)
  }

  sendMessage3(message3: boolean) {
    this.peep3.next(message3)
  }

  setTheme(themeName: string) {
    this.setThemeName.next(themeName)
  }

  sendsearch(searchName: any) {
    this.setsearch.next(searchName);
  }

  sendnavigaterefresh(data: any) {
    this.navigaterefresh.next(data);
  }

  sendshareTimeZone(data: any) {
    this.shareTimeZone.next(data);
  }

  getImageData() {
    const current = new Date();
    return this.authService.webServiceURL + "/adminPref/getLogoImage?time=" + current.getTime();
  }
}