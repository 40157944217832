import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
const USER_KEY = 'auth-user';
@Injectable({
  providedIn: 'root'
})
export class UserguardService {

  constructor() { }

  isAdmin(){
    const userJSON = sessionStorage.getItem(USER_KEY);
    alert(userJSON);
    if(userJSON['type']=='User'){
      return false;
    }
    return true;
  }

}
