import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Router, NavigationEnd } from '@angular/router';
import { TokenStorageService } from './services/token-storage/token-storage.service';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';
import { UsersDataService } from './services/users-data/users-data.service';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  ssoUrl: any = this.authService.webServiceURL + '/auth/oidc';
  title = 'ng';
  showHead: boolean = false;
  loaderStatus: boolean = false;
  routesURL: any;
  usergot: boolean = false;
  themeImageDataUser: any;
  themeImageDataAdmin: any;
  checktheme: boolean = false;
  checktheme2: boolean = false;
  checktheme3: boolean = false;
  checktheme4: boolean = false;
  selectedTheme: any;
  themeImageData: any;
  adminPref: any;
  resDATA: any;

  constructor(
    private router: Router,
    private token: TokenStorageService,
    protected authService: AuthService,
    private sharedData: SharedService,
    private usersDataService: UsersDataService
  ) { }

  ngOnInit() {
    this.updateFaviconBasedOnDomain();
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: any) => {
      this.routesURL = event['url'];
      if (this.token.getToken() != null) {
        if (this.routesURL == '/') {
          this.router.navigate(['home']);
        }
      }
    })
    this.sharedData.setThemeName$.subscribe(message => {
      this.selectedTheme = message;
      this.setDefaultTheme(this.selectedTheme);
    })
    this.authService.loaderStatus.subscribe((data: boolean) => {
      this.loaderStatus = data;
    });
    this.settingSlider();
    if (this.token.getToken() == null) {
      this.usersDataService.getAdminThemeData().subscribe((res: any) => {
        this.adminPref = res;
        this.usersDataService.adminThemeAuthType = this.adminPref.data[0].auth_type;
        if (this.adminPref.data[0].auth_type == 'basic' && this.shouldRedirectToLogin()) {
          this.router.navigate(['login']);
        } else if (this.adminPref.data[0].auth_type == 'sso' && this.shouldRedirectToLogin()) {
          const ref = document.getElementById("loginfusoclient")
          if (ref) {
            ref.click();
          }
          // this.http.get(this.authService.webServiceURL + '/auth/oidc').subscribe((res: any) => {
          //   this.resDATA = res;
          //   console.log(this.resDATA)
          //   let tokenValue: any = this.DecodeToken(res.data.token);
          //   if (!tokenValue.userData.theme_preference) {
          //     this.usersDataService.getAdminThemeData().subscribe((res) => {
          //       tokenValue.userData.theme_preference = res.data[0].color_theme;
          //       this.token.saveToken(JSON.stringify(this.resDATA.data.token));
          //       this.token.saveUser(tokenValue.userData);
          //       this.authService.hideLoader();
          //       this.router.navigate(['home']);
          //     });
          //   } else {
          //     this.token.saveToken(JSON.stringify(this.resDATA.data.token));
          //     this.token.saveUser(tokenValue.userData);
          //     this.authService.hideLoader();
          //     if (res && res.authenticated) {
          //       this.router.navigate(['/home']);
          //     }
          //   }
          // }, (error: any) => {
          //   console.log(error)
          //   if (this.shouldRedirectToLogin()) {
          //     this.router.navigate(['login']);
          //   }
          // })
        }
      })
    }
  }

  DecodeToken(token: string): string {
    return jwt_decode(token);
  };

  shouldRedirectToLogin() {
    return !this.routesURL || (
      !this.routesURL.startsWith('/forgot') &&
      !this.routesURL.startsWith('/reset') &&
      !this.routesURL.startsWith('/login') &&
      !this.routesURL.startsWith('/token')
    );
  };

  setDefaultTheme(selectedTheme: string) {
    let selectedUserDetail = this.token.getUser();
    let defaultColorTheme = this.sharedData.getThemeObject(selectedUserDetail?.theme_preference);
  };

  settingSlider() {
    let selectedUserDetail = this.token.getUser();
    if (selectedUserDetail) {
      this.usergot = true;
      this.themeImageDataUser = selectedUserDetail?.theme_preference;
    }
    else {
      this.themeImageDataAdmin = this.adminPref?.data[0]?.color_theme;
    }
  };

  updateFaviconBasedOnDomain() {
    const currentDomain = window.location.hostname;

    if (currentDomain === 'forcemon.mitsubishi-fuso.com' || currentDomain === 'forcemon-fdp.mitsubishi-fuso.com') {
      this.setFavicon('assets/images/fusofavicon.ico');
    } else if (currentDomain === 'voltasxmonservice.azurewebsites.net') {
      this.setFavicon('assets/images/voltasfavicon.ico');
    }
  };

  setFavicon(url: string) {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (link) {
      console.log(link.href);
      const baseUrl = link.href.substring(0, link.href.lastIndexOf('/') + 1);
      const newUrl = baseUrl + url;
      link.href = newUrl;
    }
  };
}