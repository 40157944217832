import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentListService {

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getEnvironmentList() {
    return this.http.get<any>(this.authService.webServiceURL + '/environmentList/findEnvironmentList')
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getEnvNameById(id: any) {
    const apiUrl = `${this.authService.webServiceURL}/environmentList/findEnvironmentNameById/${id}`;
    return this.http.get<any>(apiUrl)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  updateEnvironmentList(data: any) {
    return this.http.put<any>(this.authService.webServiceURL + '/environmentList/updateEnvironmentList', data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  updateEnvironmentsArray(data: any) {
    return this.http.put<any>(this.authService.webServiceURL + '/environmentList/updateEnvironmentsArray', data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getEnvDtl(envId: any) {
    const apiUrl = `${this.authService.webServiceURL}/env_details/${envId}`;
    return this.http.get<any>(apiUrl)
      .pipe(map((res: any) => {
        return res;
      }))
  }
}
