import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import {BehaviorSubject, Observable, Subject, Subscription, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public responseCache = new Map();
  constructor(private authService: AuthService, private http: HttpClient) { }
  public getEnv(): Observable<any>{
    var URL = this.authService.webServiceURL + '/env';
    const envListFromCache = this.responseCache.get(URL);
    if(envListFromCache){
      return of(envListFromCache);
    }
    const response = this.http.get<any>(URL, this.authService.getHeader());
    response.subscribe(envList=>this.responseCache.set(URL, envList));
    return response;
  }
}
