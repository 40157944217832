<div class="container">
  <app-header [environmentname]="environmentname"></app-header>
  <div class="row">
    <div class="col-md-6">
      <div class="mid">
        <p class="text1">
          Select <span class="parr"> Environment </span> to view the details of Machines. </p>
        <p class="heading_para">This monitoring environment provides metrics, diagnose, and respond to performance
          issues for the linked environemnt.</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="img_container">
        <div class="parent">
          <img class="image1" src="assets/images/{{themeImageData}}/Effect.png" style="  height: 400px; width:600px;" />
          <img class="image2" src="assets/images/dashboard-design.png" style="  height: 400px; width:400px;" />
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="iterrableData && iterrableData.length > 0">
    <div class="col-md-4">
      <div class="middle">
        <p class="middle-para dark-card-content">
          <span class="border-style"> Choose </span> Environment
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 choose_Env " *ngFor="let env of iterrableData">
      <div class="card mb-3 dark_env">
        <div class="row g-0 ">
          <div class="col-md-6 card-height">
            <img src="assets/images/QA_Environment.png" class="img-fluid rounded-start" alt="...">
          </div>
          <div class="col-md-6 ">
            <div class="card-body">
              <!-- <p class="card-text">{{env.desc}}</p> -->
              <p class="card-text">This monitoring environment provides metrics for {{env.name}}</p>
              <p class="card-tex" (click)="chooseEnvironment(env)">
                {{env.name}}
                &nbsp; <img src="assets/images/{{themeImageData}}/side_arrow.png" height="10" width="16">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="iterrableData && iterrableData.length === 0">
    <div class="col-md-6">
      <p class="middle-para dark-card-content">Check with administator, you don't have access to any environments.</p>
    </div>
  </div>
</div>