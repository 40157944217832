import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  invokeHeaderComponentFunction = new EventEmitter();
  // subsVar: Subscription;
  subsVar: any = Subscription;
  public responseCache = new Map();
  public searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  constructor(private authService: AuthService, private http: HttpClient) { }
  onFirstComponentButtonClick() {
    this.invokeHeaderComponentFunction.emit();
  }

  getMasterData(endpoints: string): Observable<any> {
    return this.http.get<any>(this.authService.webServiceURL + endpoints, this.authService.getHeader());
  }

  getPodData(): Observable<any> {
    return this.http.get<any>(`${this.authService.webServiceURL}/pod_details`);
  }

  private subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next(true);
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  ssoToken(data: any) {
    const token = {
      token: data
    }
    return this.http.post<any>(this.authService.webServiceURL + '/tokenAuth', token)
  }

  sendRequestWithoutData(Token: any) {
    if (!Token) {
      console.log("TOKEN IS NOT FOUND");

    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Token}`
    });
    return this.http.get('this.authService.webServiceURL', { headers });

  }

  private token: any | null = null;
  setToken(token: any): void {
    this.token = token;
    localStorage.setItem('auth-token', token);
    sessionStorage.setItem('auth-token', token);
    // Store the token in localStorage
  }

  getToken(): string | null {
    //return this.token || localStorage.getItem('authToken');
    return this.token || sessionStorage.getItem('auth-token');
  }

  clearToken(): void {
    this.token = null;
    localStorage.removeItem('auth-token');
    sessionStorage.removeItem('auth-token');
    // Remove the token from localStorage
  }

  setAuthenticatedUser(user: any): void {
    // Store the authenticated user data in sessionStorage
    sessionStorage.setItem('auth-user', JSON.stringify(user));
  }

  getAuthenticatedUser(): any | null {
    const userData = sessionStorage.getItem('auth-user');
    return userData ? JSON.parse(userData) : null;
  }
}