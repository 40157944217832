import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { DARK_MODE_OPTIONS } from 'angular-dark-mode';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth/auth.service';
import { UserguardService } from './services/user_guard/userguard.service';
import { authInterceptorProviders } from './services/_helper/auth.interceptor';
import { SiebelService } from './services/siebel/siebel.service';
import { CommonModule } from '@angular/common';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChooseEnvironmentComponent } from './components/choose-environment/choose-environment.component';
import { SsoAuthComponent } from './components/sso-auth/sso-auth.component';

@NgModule({
  declarations: [
    AppComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    SsoAuthComponent,
    //SiebellogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule
  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthService, authInterceptorProviders,
    UserguardService, SiebelService,
    {
      provide: DARK_MODE_OPTIONS,
      useValue: {
        darkModeClass: 'my-dark-mode',
        lightModeClass: 'my-light-mode'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ChooseEnvironmentComponent,
      multi: true,
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
