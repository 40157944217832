<router-outlet></router-outlet>
<div class="loader-section" *ngIf="loaderStatus">
  <div class="loader-dialog">
    <div class="loader-dialog-body">
      <div class="row">
        <div class="col-md-12" id="dashboard">
          <!-- {{themeImageDataUser}}
          {{themeImageDataAdmin}} -->
          <img *ngIf="!usergot" src="../assets/images/{{themeImageDataAdmin}}/sp.gif" class="instruction-icon"
            alt="Loading" style="width: 100px" />
          <img *ngIf="usergot" src="../assets/images/{{themeImageDataUser}}/sp.gif" class="instruction-icon"
            alt="Loading" style="width: 100px" />
        </div>
      </div>
    </div>
  </div>
</div>

<a style="width: 1px; height: 1px;" [href]="ssoUrl" [hidden]="true" id="loginfusoclient"></a>