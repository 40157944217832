import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EnvService } from 'src/app/services/env/env.service';
import { HeaderComponent } from '../header/header.component';
import { SharedService } from 'src/app/services/shared/shared.service';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';
import { AppComponent } from 'src/app/app.component';
import { UsersDataService } from 'src/app/services/users-data/users-data.service';
import { EnvironmentListService } from 'src/app/services/environmentList/environment-list.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-sso-auth',
  templateUrl: './sso-auth.component.html',
  styleUrls: ['./sso-auth.component.css']
})
export class SsoAuthComponent implements OnInit {

  envData: any;
  iterrableData: any;
  initialdata: any;
  checktheme: boolean = false;
  checktheme2: boolean = false;
  checktheme3: boolean = false;
  checktheme4: boolean = false;
  selectedTheme: any;
  themeImageData: any;
  newToken: any
  adminPref: any
  loaderStatus: boolean = false;
  themeImageDataUser: any;
  themeImageDataAdmin: any;
  usergot: boolean = false;
  SSOToken: any;
  SSOouser: any;
  message: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private env: EnvService,
    private sharedData: SharedService,
    private userdata: UsersDataService,
    private tokenStorage: TokenStorageService,
    private appCOmponent: AppComponent,
    private envservice: EnvironmentListService,
    private activateroute: ActivatedRoute,
    private homeservice: HomeService,

  ) { }

  ngOnInit(): void {
    // this.activateroute.queryParams.subscribe(params => {
    //   this.newToken = params['newtoken'];
    // });
    this.newToken = this.activateroute.snapshot.queryParamMap.get('token');
    // console.log(this.newToken)
    if (this.newToken) {
      try {
        this.setToken()
      } catch (error) {
        console.log('Token validation error:', error);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  ValidateToken() {
    this.homeservice.ssoToken(this.newToken).subscribe((res) => {
      if (res && res.token) {
        this.SSOToken = res.token;
        this.SSOouser = res.user;
        // console.log("SSOToken", this.SSOToken)
        // console.log("SSOouser", this.SSOouser)
        this.tokenStorage.saveToken(JSON.stringify(this.SSOToken))
        this.tokenStorage.saveUser(res.user)
        this.message = "Login Successfully"
        this.router.navigate(['/home']);
      }
    }, (e) => {
      this.message = "You are not authorized"
      this.router.navigate(['/login']);
      console.log("error", e)
    })
  }

  setToken() {
    this.SSOToken = this.newToken;
    this.SSOouser = jwt_decode(this.SSOToken);
    this.tokenStorage.saveToken(JSON.stringify(this.SSOToken))
    this.tokenStorage.saveUser(this.SSOouser.userData)
    this.message = "Login Successfully"
    this.router.navigate(['/home']);
  }
}
