<div class="container">
  <div class="main-container">
    <div class="row main-cont">
      <div class="col-md-6" *ngIf="apiResponseReceived">
        <div class="img-container">
          <div class="img-cont">
            <!-- <img *ngIf="!imageURL" src="https://cubastion.com/assets/images/cubastion_up.svg" alt="Cinque Terre" width="200" height="100" /> -->
            <img *ngIf="imageURL" [src]="imageURL" alt="Cinque Terre" style=" margin-bottom: 20px;" class="main-img" />
          </div>
          <div class="para-cont">
            <p style="font-family:'Nunito Sans'; letter-spacing: 0px; color: #707070; font-size:40px;">
              Hello, Welcome to <span class="spandesign">
                {{loginDescription}}
              </span></p>
          </div>
          <div class="last-para" *ngIf="tokenIsValid">
            <p> Please Reset your Password.</p>
          </div>
          <div class="last-para" *ngIf="!tokenIsValid">
            <p> Reset token is expired or invalid.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="apiResponseReceived">
        <div class="login-container" *ngIf="tokenIsValid">
          <h3 class="loginDesign">Reset Password</h3>
          <div class="form_container">
            <form [formGroup]="formUpate">
              <div class="logo_cont">
                <!-- <input type="text" formControlName="email" placeholder="Enter Email"
                  style=" font-family: 'Nunito Sans'; padding-bottom: 5px;">
                <i class="fa fa-user icon" style="color: #707070;
                  font-size: 20px;
                  margin-left: -20px; margin-top:2px;"></i> -->
                <p class="forget_pass" style="font-size: 20px; 
                  margin-left: 8px; margin-top:2px; margin-bottom: -10px; ">{{userEmail}}</p>
              </div>
              <br><br>
              <div class="logo_cont">
                <input [type]="passwordt" formControlName="password" placeholder="Enter New Password" style=" width: 100%; padding: 0px 0px;margin: 8px 0; box-sizing: border-box;border: none;
                  border-bottom: 1px solid gray;  font-family: 'Nunito Sans'; padding: 5px;">
                <div class="img-conte">
                  <img src="assets/images/eye.png" alt="Cinque Terre" width="18" height="12" *ngIf="show"
                    (click)="onClick()">
                  <img src="assets/images/eyeShow.png" alt="Cinque Terre" width="20" height="16" *ngIf="!show"
                    (click)="onClick()">
                </div>
              </div>
              <br><br>
              <div class="logo_cont">
                <input [type]="passwordt" formControlName="confirmPassword" placeholder="Confirm Password" style=" width: 100%;
                        padding: 0px 0px;
                        margin: 8px 0;
                        box-sizing: border-box;
                        border: none;
                        border-bottom: 1px solid gray;  font-family: 'Nunito Sans'; padding: 5px;">
                <div class="img-conte">
                  <!-- <img src="assets/images/eye.png" alt="Cinque Terre" width="18" height="12" *ngIf="showC"
                      (click)="onClickConfirm()">
                    <img src="assets/images/eyeShow.png" alt="Cinque Terre" width="20" height="16" *ngIf="!showC"
                      (click)="onClickConfirm()"> -->
                </div>
              </div>
              <br>
              <div style="display: flex; justify-content:center; margin-top:15px;">
                <input class="btn btn-primary" type="submit" (click)="updatedPassworddetails()" value="Update"
                  style="font-weight: 600;margin-top:15px;">
              </div>
            </form>
          </div>
        </div>
        <div class="loginDesign" *ngIf="!tokenIsValid">
          <p>Click <span (click)="navigatetoLogin()" class="spann"><b>here </b></span>to reset your password.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="backdrop _hModal modal fade" id="success">
  <div class="modal-dialog md-d">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <button type="button" class="close" (click)="closeModal()">
          <!-- <i class="fas fa-times"></i> -->
        </button>
        <p id="errmessage" [innerHTML]="errmessage"></p>
      </div>
    </div>
  </div>
</div>