import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
//import { io } from 'socket.io-client';
import * as io from 'socket.io-client'
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class SiebelService {
  private baseUrl = this.authService.SiebelServiceUrl;
  private baseUrlAPI = this.authService.webServiceURL;

  constructor(private http: HttpClient,
              private authService:AuthService) {
    // this.socket = io.connect(this.baseUrl);
  }

  
  callEvent(eventName: string) {
    return this.http.post<any>(`${this.baseUrl}/call-event`, { eventName });
  }

  //* siebel server
  getSiebelServers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/servers`);
  }

  //* stop Component
  stopComponent(serverName: string, compName: string): Observable<any> {
    const url = `${this.baseUrl}/stopComp?serverName=${serverName}&compName=${compName}`;
    return this.http.post(url, {});
  }

  //* start component
  startComponent(serverName: string, compName: string): Observable<any> {
    const url = `${this.baseUrl}/startComp?serverName=${serverName}&compName=${compName}`;
    return this.http.post(url, {});
  }

  //* server component
  getServerComponents(serverName: string): Observable<any> {
    const url = `${this.baseUrl}/serverComponents`;
    const params = { serverName };
    return this.http.get(url, { params });
  }

  //* set comp log level
  setCompLogLevel(serverName: string, compName: string, logLevel: number): Observable<any> {
    const url = `${this.baseUrl}/setCompLogLevel?serverName=${serverName}&compName=${compName}&logLevel=${logLevel}`;
    return this.http.post(url, null);
  }

  // Create a method to fetch siebeldata based on serverName and compName

  getSiebelData(serverName: string, compName: string): Observable<any> {
    const url = `${this.baseUrl}/compTasks?serverName=${serverName}&compName=${compName}`;
    return this.http.get(url);
  }


  //* download task file
  downloadTaskFile(taskId: string, serverName: string): Observable<Blob> {
    const url = `${this.baseUrl}/downloadTaskFile`;
    const body = { taskId, serverName };
    return this.http.post<Blob>(url, body, { responseType: 'blob' as 'json' });
  }

  // admin
  private socket: any;

  connect(): void {
    if (!this.socket) {
      this.socket = io.connect(this.baseUrl);
  }
}

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  async sendMessage(eventName: string, eventData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
    console.log('finally emitting message from frontend', eventName, eventData);
    this.socket.emit(eventName, eventData);
    resolve(); // Resolve the promise when the message is sent
    });
  }

  receiveMessage(eventName: string): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(eventName, (data: any) => {
        observer.next(data);
      });
    });
  }

  //get activity logs
  getActivityLog(page: number, pageSize: number): Observable<any> {
    const url = `${this.baseUrl}/activityLogs`;
    const params = { page: page.toString(), pageSize: pageSize.toString() };
    return this.http.get(url, { params });
  }

  // searchfile
  searchFile(fileName: any) {
    const url = `${this.baseUrlAPI}/siebel-search`;
    // const formData = new FormData();
    // formData.append('fileName', fileName);
    // return this.http.post<any[]>(url, formData);
    return this.http.post<any[]>(url, {'fileName': fileName});
  }

  //downloadfile

  downloadFile(fileName: string) {
    // const url = `${this.baseUrl}/downloadFile?fileName=`;
    // window.open(url+ encodeURIComponent(fileName), '_blank');
    const url = `${this.baseUrlAPI}/downloadFile`;
    return this.http.post<any[]>(url, {'fileName': fileName}, {responseType: 'blob' as 'json'});
  }

  // deletefile

  deleteFile(fileName: string) {
    const url = `${this.baseUrlAPI}/deleteFile`;
    return this.http.post<any>(url, { deleteFileName: fileName });
  }

  // get activity log by action
  getActivityLogByUser(user:string): Observable<any> {
    const url = `${this.baseUrl}/activityUserLogs/${user}`;
    return this.http.get(url);
  }

  // get activity log by user
  getActivityLogByAction(action:string): Observable<any> {
    const url = `${this.baseUrl}/activityAdminLogs/${action}`;
    return this.http.get(url);
  }

}
