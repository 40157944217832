import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/services/shared/shared.service';
import { EnvironmentListService } from 'src/app/services/environmentList/environment-list.service';
import { AppComponent } from 'src/app/app.component';
import { UsersDataService } from 'src/app/services/users-data/users-data.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() environmentname: string;
  checktheme: boolean = false;
  checktheme2: boolean = false;
  checktheme3: boolean = false;
  checktheme4: boolean = false;
  selectedTheme: any;
  themeImageData: any;
  imageURL: any;
  userName: any;
  adminPref: any;

  constructor(
    private route: Router,
    private token: TokenStorageService,
    private http: HttpClient,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private _loc: Location,
    private sharedData: SharedService,
    private envservice: EnvironmentListService,
    private appComponent: AppComponent,
    private userData: UsersDataService
  ) { };

  currentUrl: any;
  ngOnInit(): void {
    this.appComponent.settingSlider();
    this.getImageUrl();
    this.currentUrl = this.route.url;
    this.sharedData.setThemeName$.subscribe(message => {
      this.selectedTheme = message;
      this.setDefaultTheme(this.selectedTheme);
    })
    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.searchFunctionality = true;
        this.production = true;
        $('.navbar').each(function () {
          $(this).addClass('nav');
        });
      }
    })
    // this.sharedService.sendsearch(this.stateSearch);
    let loc = this._loc.path();
    let id = this.activatedRoute.snapshot.queryParams.id;
    if (loc == '/home') {
      this.searchFunctionality = false;
      this.production = false;
      // $(this).removeClass('nav');
    }
    if (loc == "/env?id=" + id || loc == "/env") {
      this.searchFunctionality = true;
      this.production = true;
      $('.navbar').each(function () {
        $(this).addClass('nav')
      })
    }
    this.route.events.subscribe((event: any) => {
      setTimeout(() => {
        let id = this.activatedRoute.snapshot.queryParams.id;
        if (event['url'] !== undefined) {
          if (event['url'] === '/home') {
            this.searchFunctionality = false;
            this.production = false;
            // $(this).removeClass('nav');           
          }
          if (event['url'] == "/env?id=" + id || loc == "/env") {
            this.searchFunctionality = true;
            this.production = true;
            $('.navbar').each(function () {
              $(this).addClass('nav')
            })
          }
        }
      }, 10);
    })
    // this.activatedRoute.queryParams.subscribe(params => {
    //   //this.fetchEnvNameById();
    // });
    if (this.production === true) {
      this.fetchEnvNameById(this.activatedRoute.snapshot.paramMap.get('id'))
    }
    this.getEnvironmentList()
  };

  changeThemeSettings(themeSeetings) {
    document.documentElement.style.setProperty('--primary-color', themeSeetings.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', themeSeetings.secondaryColor);
    document.documentElement.style.setProperty('--third-color', themeSeetings.thirdColor);
    document.documentElement.style.setProperty('--fourth-color', themeSeetings.fourtFirst);
    document.documentElement.style.setProperty('--fifth-color', themeSeetings.fifthColor);
  };

  setDefaultTheme(selectedTheme: string) {
    let selectedUserDetail = this.token.getUser();
    this.userName = selectedUserDetail?.name;
    // console.log("userName is=>", this.userName);    
    // if(!selectedTheme){
    let defaultColorTheme = this.sharedData.getThemeObject(selectedUserDetail?.theme_preference);
    this.themeImageData = selectedUserDetail?.theme_preference;
    // console.log("defaultColorTheme from home",defaultColorTheme)

    this.changeThemeSettings(defaultColorTheme);
    // }
  };

  stateSearch: any;
  searchFunctionality: boolean = false;
  production: boolean = false;
  gettingSearch(event: any) {
    this.sharedData.sendsearch(this.stateSearch);
  };

  json: any;
  logout() {
    if (!this.userData.adminThemeAuthType || this.userData.adminThemeAuthType === 'sso') {
      this.userData.getAdminThemeData().subscribe((res: any) => {
        this.adminPref = res;
        this.userData.adminThemeAuthType = this.adminPref?.data[0]?.auth_type;
      });

      if (this.userData.adminThemeAuthType === 'sso') {
        this.http.get<any>(`${this.authService.webServiceURL}/logout`, this.json).subscribe((res: any) => {
          console.log(res.data)
          let redirectUrl = res.data || 'https://login.mercedes-benz.com/signout?loggedout=true';
          window.sessionStorage.clear();
          // this.route.navigateByUrl(redirectUrl);
          window.location.href = redirectUrl;
          this.token.destroyToken();
        }, (err: any) => {
          let redirectUrl = 'https://login.mercedes-benz.com/signout?loggedout=true';
          window.sessionStorage.clear();
          window.location.href = redirectUrl;
          this.token.destroyToken();
          console.log(err)
        });
      }
    } else {
      this.token.destroyToken();
      this.route.navigate(['login']);
    }
  };

  goToHome() {
    this.route.navigate(['home']);
  };

  navigateToAdmin() {
    this.route.navigate(['admin']);
  };

  getImageUrl() {
    const current = new Date();
    setTimeout(() => {
      this.imageURL = this.sharedData.getImageData();
    }, 10);
  };
  showTable: boolean = false;

  //ACTIVITY LOG
  // logData = [];

  // getActivityLogByAction(action: string) {
  //   this.service.getActivityLogByAction(action).subscribe(data => {
  //     this.logData = data;
  //     console.log("data", this.logData)
  //   },
  //     (error: any) => {
  //       console.error('Error fetching data:', error);
  //     })
  // }
  // getActivityLogByUser(user: string) {

  //   this.service.getActivityLogByUser(user).subscribe(data => {
  //     this.logData = data;
  //     console.log("data", this.logData)
  //   },
  //     (error: any) => {
  //       console.error('Error fetching data:', error);
  //     })
  // }
  // currentPage = 1;
  // pageSize = 20;
  // totalLogs = 0;
  // totalPages = 0;
  // getActivitylog(page: number = 1): void {
  //   this.currentPage = page;
  //   this.service.getActivityLog(page, this.pageSize).subscribe(
  //     data => {
  //       this.logData = data.logs;
  //       this.totalLogs = data.totalLogs;
  //       this.totalPages = Math.ceil(this.totalLogs / this.pageSize);
  //     },
  //     error => {
  //       console.error('Error fetching data:', error);
  //     }
  //   );
  // }
  // prevPage(): void {
  //   if (this.currentPage > 1) {
  //     this.getActivitylog(this.currentPage - 1);
  //   }
  // }

  // nextPage(): void {
  //   if (this.currentPage < this.totalPages) {
  //     this.getActivitylog(this.currentPage + 1);
  //   }
  // }

  namesArray: any = [];
  getEnvironmentList() {
    this.envservice.getEnvironmentList().subscribe(
      (res: any) => {
        this.namesArray = res.data.map((env: any) => env) || [];
      },
      (error) => {
        console.log(error);
      }
    );
  };

  env_name: any;
  fetchEnvNameById(envId: any) {
    this.envservice.getEnvNameById(envId).subscribe((res) => {
      this.env_name = res.data[0].name
    }, (error) => {
      console.log(error)
    });
  };

  onSettingsClick() {
    this.userData.setPreviousUrl(this.currentUrl);
  };

  firstRecordId: any;
  onclickenv(env: any) {
    this.envservice.getEnvDtl(env).subscribe((res) => {
      if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
        this.fetchEnvNameById(env);
        this.firstRecordId = res.data[0].id;
        this.authService.hideLoader();
        const dltid = this.firstRecordId;
        const url = `/env/${env}/dlt/${dltid}/monitoring`;
        // this.route.navigate([url], { relativeTo: this.activatedRoute })
        this.route.navigate([url]);
        this.sharedData.sendnavigaterefresh(url);
      }
    }, (e) => {
      console.log('Error:', e);
    });
  };

  refreshPage() {
    this.sharedData.sendnavigaterefresh('Refresh')
  }
}