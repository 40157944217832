import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken() {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public destroyToken() {
    return sessionStorage.clear();
  }

  public checkTokenExpiry() {
    const token = this.getToken();
    if (token) {
      const decodedToken: { exp: number } = jwt_decode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000); //Server Time

      return decodedToken.exp <= currentTimestamp;
    }
    return true;
  };

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    const userJSON = sessionStorage.getItem(USER_KEY);
    if (userJSON !== null) {
      return JSON.parse(userJSON);
    }
  }

  public isAdmin() {
    const userJSON = JSON.parse(sessionStorage.getItem(USER_KEY));
    if (userJSON['type'] == 'Admin') {
      return true;
    }
    return false;
  }

  public updateThemeToken(themeName: any, darkTheme: any) {
    const userJSON = sessionStorage.getItem(USER_KEY);
    if (userJSON !== null) {
      let jsonObj = JSON.parse(userJSON);
      jsonObj.theme_preference = themeName;
      jsonObj.dark_mode_active = darkTheme;
      window.sessionStorage.setItem(USER_KEY, JSON.stringify(jsonObj));
      return JSON.stringify(themeName, darkTheme);
    }
  }
}