import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';
import { UsersDataService } from 'src/app/services/users-data/users-data.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

import jwt_decode from 'jwt-decode';
import { Token } from '@angular/compiler';
// import * as $ from 'jquery';
declare var $: any;
const accessToken = 'access-token';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  errmessage: any;
  admin_color: any;
  submitted: boolean = false;
  imageURL: string;
  loginDescription: any;
  checktheme: boolean = false;
  checktheme2: boolean = false;
  checktheme3: boolean = false;
  checktheme4: boolean = false;
  selectedTheme: string = 'defaultTheme'
  themeImageData: any;
  loginTheme: any;
  selectedThemeName: any;
  passwordt;
  passwordC;
  tokenIsValid: boolean = false;
  apiResponseReceived: boolean = false;

  show = false;
  showC = false;
  input: any;
  formUpate!: FormGroup
  usertoken: any
  userEmail: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private usersDataService: UsersDataService,
    private sharedData: SharedService,
    private ActivatedRoute: ActivatedRoute,
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.appComponent.settingSlider();
    this.ActivatedRoute.paramMap.subscribe((params) => {
      this.usertoken = params.get('token');
    });

    this.usersDataService.validateToken(this.usertoken).subscribe(
      (response: any) => {
        if (response.message === 'Token is valid') {
          this.tokenIsValid = true;
          this.userEmail = response.email;
        } else {
          this.tokenIsValid = false;
        }
        this.apiResponseReceived = true;
      },
      (error: any) => {
        this.tokenIsValid = false;
        this.apiResponseReceived = true;
        console.log('An error occurred while validating the token.');
      });

    this.formUpate = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl('')
    })


    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    })

    this.getImageUrl();
    this.setDefaultTheme('');
    this.passwordt = 'password'
    this.passwordC = 'password'
  }

  onClick() {
    if (this.passwordt === 'password') {
      this.passwordt = 'text';
      this.show = true;
    } else {
      this.passwordt = 'password';
      this.show = false;
    }
  }

  onClickConfirm() {
    if (this.passwordC === 'password') {
      this.passwordC = 'text';
      this.showC = true;
    } else {
      this.passwordC = 'password';
      this.showC = false;
    }
  }

  changeThemeSettings(themeSeetings) {
    // console.log('themeSeetings', themeSeetings);
    this.selectedThemeName = this.loginTheme;
    document.documentElement.style.setProperty('--primary-color', themeSeetings.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', themeSeetings.secondaryColor);
    document.documentElement.style.setProperty('--third-color', themeSeetings.thirdColor);
    document.documentElement.style.setProperty('--fourth-color', themeSeetings.fourtFirst);
    document.documentElement.style.setProperty('--fifth-color', themeSeetings.fifthColor);
    this.sharedData.setTheme(this.loginTheme)
  }

  setDefaultTheme(selectedTheme: string) {
    this.usersDataService.getAdminThemeData().subscribe((res) => {
      this.admin_color = res?.data[0]?.color_theme;
      let defaultColorTheme = this.sharedData.getThemeObject(this.admin_color);
      this.changeThemeSettings(defaultColorTheme);
      this.loginDescription = res?.data[0]?.description;
      this.loginTheme = res?.data[0]?.color_theme;
    });
  }

  get validate() {
    return this.loginForm.controls;
  }

  resDATA: any;
  onSubmit(json: any) {
    this.submitted = true;
    // console.log(this.submitted, this.validate);
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.showLoader();
    // console.log(this.authService.webServiceURL + '/auth/login')
    this.http.post(this.authService.webServiceURL + '/auth/login', json).subscribe(
      (res: any) => {
        this.resDATA = res;
        if ((res.error == "No such User exists")) {
          this.authService.hideLoader();
          this.errmessage =
            '<p>Incorrect password. You are not authorized to login</p>';
          // this.loginForm.reset();
          this.loginForm.get('password')?.reset('');
          // console.log(this.loginForm)
          $('#success').modal('show');
          return;
        }
        let tokenValue: any = this.DecodeToken(res.data.token);
        if (!tokenValue.userData.theme_preference) {
          this.usersDataService.getAdminThemeData().subscribe((res) => {
            // console.log("adminDataTheme login", res.data[0].color_theme);
            tokenValue.userData.theme_preference = res.data[0].color_theme;
            this.tokenStorage.saveToken(JSON.stringify(this.resDATA.data.token));
            this.tokenStorage.saveUser(tokenValue.userData);
            this.authService.hideLoader();
            // this.router.navigate(['home']);
            // if (tokenValue.userData.status === 'Pending') {
            //   this.router.navigate(['reset-password']);
            // } else {
            //   this.router.navigate(['home']);
            // }
          });
        } else {
          this.tokenStorage.saveToken(JSON.stringify(this.resDATA.data.token));
          this.tokenStorage.saveUser(tokenValue.userData);
          this.authService.hideLoader();
          // this.router.navigate(['home']);
          // if (tokenValue.userData.status === 'Pending') {
          //   this.router.navigate(['reset-password']); //need to pass token ID
          // } else {
          //   this.router.navigate(['home']);
          // }
        }
      },
      (err: any) => {
        // console.log("Error is coming!!!!!");
        this.authService.hideLoader();
        var res = err.error;

        if (res.email != undefined) {
          this.errmessage = '<p>' + res.email[0] + '';
        }
        if ((res.error = "No such User exists")) {
          this.errmessage =
            '<p>Incorrect password. You are not authorized to login</p>';
        }
        // this.loginForm.reset();
        this.loginForm.get('password')?.reset('');
        $('#success').modal('show');
      }
    );
  }

  closeModal() {
    $('#success').modal('hide');
  }

  DecodeToken(token: string): string {
    // console.log("token",jwt_decode(token))
    return jwt_decode(token);
  }

  getImageUrl() {
    const current = new Date();
    setTimeout(() => {
      this.imageURL = this.authService.webServiceURL + "/adminPref/getLogoImage?time=" + current.getTime();
    }, 10);
  }

  updatedPassworddetails() {
    let UpdatedData = {
      email: this.userEmail,
      password: this.formUpate.value.password,
      confirmPassword: this.formUpate.value.confirmPassword,
      pass_token: this.usertoken
    };

    if (UpdatedData.password !== UpdatedData.confirmPassword) {
      alert("Passwords do not match. Please make sure both passwords match.");
      this.formUpate.reset()
      return
    }
    this.authService.showLoader();
    this.usersDataService.UpdatePasswordMail(UpdatedData)
      .subscribe(
        (res: any) => {
          this.authService.hideLoader();
          if (res.message === 'Password reset successful') {
            alert("Your Password has been updated Successfully");
            this.router.navigate(['login']);
            let ref = document.getElementById('cancel')
            ref?.click();
            this.formUpate.reset();
          } else if (res.message === 'User not found') {
            alert('User not found. Please check the email address.');
          } else if (res.message === 'Invalid or expired token') {
            alert('Invalid or expired token. Please request a new password reset.');
          } else if (res.message === 'Token has expired') {
            alert('Token has expired. Please request a new password reset.');
          }
        },
        (error) => {
          this.authService.hideLoader();
          alert("An error occurred while updating your password. Please try again.");
          console.log("Error occurred while updating password : ", error.message);
        }
      );
  }

  navigatetoLogin() {
    this.router.navigate(['login']);
  }
}
