import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { SsoAuthComponent } from './components/sso-auth/sso-auth.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'forceui', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then((m) => m.LoginModule)
  },
  { path: 'token', component: SsoAuthComponent },
  {
    path: 'home',
    loadChildren: () => import('./components/choose-environment/choose-environment.module').then((m) => m.ChooseEnvironmentModule)
  },
  {
    path: 'env/:id/dlt/:dltid',
    loadChildren: () => import('./components/main/main.module').then((m) => m.MainModule)
  },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'forgot-password/:token', component: ForgetPasswordComponent },
  { path: 'Admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'Users', loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule) },
  { path: 'Preferences', loadChildren: () => import('./components/preferences/preferences.module').then(m => m.PreferencesModule) },
  { path: 'Environment', loadChildren: () => import('./components/environment/environment.module').then(m => m.EnvironmentModule) },
  { path: 'Maintenance Window', loadChildren: () => import('./components/maint-window/maint-window.module').then(m => m.MaintWindowModule) },
  { path: 'leftpanel', loadChildren: () => import('./components/leftpanel/leftpanel.module').then(m => m.LeftpanelModule) },
  // { path: 'reset-password/:token', loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },  
  { path: '**', loadChildren: () => import('./components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }