import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {TokenStorageService} from '../token-storage/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService  {

  webServiceURL: any;
  SiebelServiceUrl:any
  options = {};
  userLoginDetails: any = [];
  public loaderStatus = new Subject<boolean>();

  constructor(private token: TokenStorageService,
    private router: Router) {
    this.webServiceURL = environment.APIEndpoint
    this.SiebelServiceUrl = environment.SiebelAPIEndpoint
  }

  getHeader(){
    return (this.options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  setDate(_object: any){
    this.userLoginDetails.push(_object);
  }

  public showLoader(){
    this.loaderStatus.next(true);
  }

  public hideLoader(){
    this.loaderStatus.next(false);
  }

  public convertDateInto(date: any){
    var finalDate = "";
    let orgDate = date;
    var jpnDate = new Date(date).toLocaleString('en-us', {timeZone: 'JST'});
    var dateArray = jpnDate.split("/");
    if (parseInt(dateArray[0]) <= 9 && parseInt(dateArray[1]) <= 9) {
      finalDate = "0" + dateArray[0] + "-0" + dateArray[1]
    } else if (parseInt(dateArray[0]) <= 9) {
      finalDate = "0" + dateArray[0] + "-" + dateArray[1]
    } else if (parseInt(dateArray[1]) <= 9) {
      finalDate = dateArray[0] + "-0" + dateArray[1]
    } else {
      finalDate = dateArray[0] + "-" + dateArray[1]
    }
    dateArray = dateArray[2].split(",")
    finalDate = dateArray[0] + "-" + finalDate;
    return finalDate;
  }
}
