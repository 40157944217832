<header>
  <div class="navbar">
    <div class="col header-cont">
      <div class="img">
        <a [routerLink]="['/home']">
          <img [src]="imageURL" class="main-img" alt="Logo">
        </a>
      </div>
    </div>
    <div class="col-md-6" *ngIf="searchFunctionality">
      <div class="search_container">
        <div class="text_container">
          <input type="text" id="fname" name="firstname" placeholder="Search" [(ngModel)]="stateSearch"
            (input)="gettingSearch($event)"
            style="font-size: 12px; font-family: 'Nunito Sans';  letter-spacing: 0px; color: #707070;padding-top: 9px;">
        </div>
        <div class="icon_container" style="margin-top: -4px;">
          <img src="assets/images/zoom.png" alt="PFP" width="12" height="12">
        </div>
      </div>
    </div>

    <div class="col header-cont customrefresh" *ngIf="production">
      <div class="prod_div">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle btt1" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            {{env_name}}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li *ngFor="let env of namesArray">
              <a class="dropdown-item drop1" (click)="onclickenv(env.id)">{{ env.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="prod_div" style="cursor: pointer; margin: left 10px;" (click)="refreshPage()">
        <svg width="12" height="12" class="refreshcolor" fill="#000000" version="1.1" id="Layer_1"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 383.748 383.748"
          xml:space="preserve">
          <title>Refresh</title>
          <g>
            <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30
		C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593
		L2.081,34.641v113.365h113.91L62.772,95.042z" />
            <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042
		c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888
		c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z" />
          </g>
        </svg>
      </div>
    </div>

    <div class="col content_user header-cont">
      <div class="image_container">
        <!-- <img src="assets/images/{{themeImageData}}/User_profile.png" alt="Cinque Terre" width="20" height="20"> -->
        &nbsp;
        <!-- <img routerLink="/siebel-log" src="assets/images/{{themeImageData}}/wrench.png" alt="Cinque Terre" width="20" height="20"> -->
        <!-- <div style="  position: relative;">
          <a style="  position: absolute; top: -8px; right: -17px;" class="btn" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight" (click)=" getActivitylog()" aria-controls="offcanvasRight">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
              <path
                d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
            </svg>
          </a>
        </div> -->
        &nbsp;&nbsp;&nbsp;
        <img src="assets/images/{{themeImageData}}/User_profile.png" alt="Cinque Terre" width="20" height="20">
        <div class="user_div">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false" style="   color: #707070;">
              {{userName}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a [routerLink]="['/Preferences']" class="dropdown-item" (click)="onSettingsClick()"><img
                    src="assets/images/Icon material-settings.png" width="13" height="13" /> &nbsp; &nbsp; Settings</a>
              </li>
              <li><a class="dropdown-item" (click)="logout()" id="loggoutofapplication"> <img
                    src="assets/images/logout.png" width="13" height="13" />
                  &nbsp; &nbsp; Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>



</header>
<!-- <div class="offcanvas-body">
  <div *ngFor="let logEntry of logData; " class="">

    <div class="log-entry">
      <div class="log-info">
        <b>
          <p class="log-text"> {{ logEntry.log }}</p>
        </b>

        <a class="filtericon">
          <div class="dropdown">
            <a class="filtericon">
              <svg class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" xmlns="http://www.w3.org/2000/svg"
                width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                <path
                  d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
              </svg>
            </a>
            <div class="dropdown-options">
              <div class="header-container">
                <a class="small-header" (click)="getActivityLogByAction(logEntry.action)">Action</a>
              </div>
              <div class="header-container">
                <a class="small-header" (click)="getActivityLogByUser(logEntry.user)">User</a>
              </div>
            </div>

          </div>
        </a>
      </div>
    </div>
    <div class="log-entry">
      <div class="log-info">
        <p class="log-text actiontag"> Action: {{ logEntry.action }}</p>
        <p class="filtericon"
          [ngClass]="{'success-response': logEntry.response === 'Success', 'failure-response': logEntry.response === 'Failure'}">
          Response: {{ logEntry.response }}
        </p>
      </div>
    </div>

    <hr style=" border-bottom: 2px solid black; margin-bottom: 10px;">
  </div>
</div> -->
<!-- <div class="offcanvas offcanvas-end  offcanvas-body-with-margins" tabindex="-1" id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <div style="  position: relative;">
      <h5 class="offcanvas-title" id="offcanvasRightLabel">Activity Log</h5>


      <a style="position: absolute;top: 7px; left: 300px;" (click)=" getActivitylog()">
        <svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 14px;" width="16" height="18" fill="currentColor"
          class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
          <path
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>
      </a>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div *ngFor="let logEntry of logData; " class="">

      <div class="log-entry">
        <div class="log-info">
          <b>
            <p class="log-text"> {{ logEntry.log }}</p>
          </b>

          <a class="filtericon">
            <div class="dropdown">
              <a class="filtericon">
                <svg class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                  <path
                    d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                </svg>
              </a>
              <div class="dropdown-options">
                <div class="header-container">
                  <a class="small-header" (click)="getActivityLogByAction(logEntry.action)">Action</a>
                </div>
                <div class="header-container">
                  <a class="small-header" (click)="getActivityLogByUser(logEntry.user)">User</a>
                </div>
              </div>

            </div>
          </a>
        </div>
      </div>
      <div class="log-entry">
        <div class="log-info">
          <p class="log-text actiontag"> Action: {{ logEntry.action }}&ensp;</p>
          <p class="filtericon"
            [ngClass]="{'success-response': logEntry.response === 'Success', 'failure-response': logEntry.response === 'Failure'}">
            &ensp;Response: {{ logEntry.response }}
          </p>
        </div>
      </div>

      <hr style=" border-bottom: 2px solid black; margin-bottom: 10px;">
    </div>
  </div>
  <div class="pagination">
    <button class="btn btn-custom btn-dark btn-sm" (click)="prevPage()" [disabled]="currentPage === 1">
      Previous
    </button>
    <span class="log-text">Page {{ currentPage }} of {{ totalPages }}</span>
    <button class="btn btn-custom1 btn-dark btn-sm" (click)="nextPage()" [disabled]="currentPage === totalPages">
      Next
    </button>
  </div>
</div> -->