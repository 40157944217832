import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, take } from 'rxjs';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class UsersDataService {
  hasaccess: any;
  adminThemeAuthType: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getUsersData() {
    return this.http.get<any>(this.authService.webServiceURL + '/users/findUsers')
      .pipe(map((res: any) => {
        return res;
      }))
  }

  postUsersData(data: any) {
    return this.http.post<any>(this.authService.webServiceURL + '/users/createUser', data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  emailUser(data: any) {
    return this.http.post<any>(this.authService.webServiceURL + '/inviteNewUser', data)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  deleteUsersData(data: any) {
    return this.http.delete<any>(this.authService.webServiceURL + '/users/deleteUsers/' + data)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  updateUsersData(data: any) {
    return this.http.put<any>(this.authService.webServiceURL + '/users/updateUsers', data)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAdminThemeData() {
    return this.http.get<any>(this.authService.webServiceURL + '/adminPref/findAdminPref')
      .pipe(map((res: any) => {
        return res;
      }));
  }

  saveAdminThemeData(data: any, formData: any) {
    return this.http.post<any>(this.authService.webServiceURL + '/adminPref/updateAdminPref', formData, data)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAdminSSOData() {
    return this.http.get<any>(this.authService.webServiceURL + '/adminPref/findAdminSSO')
      .pipe(map((res: any) => {
        return res;
      }));
  }

  createEnvUser(obj: any) {
    const data = { email: obj.email, env_dtl_id: obj.env_dtl_id, env_id: obj.env_id };
    return this.http.post<any>(this.authService.webServiceURL + '/createEnvUser', data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUserEnv(env_dtl_id: any) {
    const apiUrl = `${this.authService.webServiceURL}/userEnv/${env_dtl_id}`;
    return this.http.get<any>(apiUrl)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  
  //delete user environment record
  deleteUsersEnvData(userId: any, env_dtl_id: string) {
    return this.http.delete<any>(`${this.authService.webServiceURL}/deleteuserEnv/${userId}/${env_dtl_id}`)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  //get env_id/env_dtl_id through
  getUserAccess(userId: string) {
    const apiUrl = `${this.authService.webServiceURL}/accessUsers/${userId}`;
    return this.http.get<any[]>(apiUrl)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTimeZone() {
    const apiUrl = `${this.authService.webServiceURL}/timezone`;
    return this.http.get<any[]>(apiUrl)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  updateEnvDetails(obj: any) {
    const data = { siebel_ops: obj.siebel_ops, id: obj.id };
    return this.http.put<any>(`${this.authService.webServiceURL}/editEnvDtl`, data)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  sendResetPasswordEmail(email: any) {
    return this.http.post<any>(`${this.authService.webServiceURL}/forgotpassword`, email)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  resetPasswordUser(email: any) {
    return this.http.post<any>(`${this.authService.webServiceURL}/resetPassword`, email)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  UpdatePasswordMail(data: any): Observable<any> {
    return this.http.post<any>(`${this.authService.webServiceURL}/updatepassword`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  validateToken(data: any): Observable<any> {
    return this.http.post<any>(`${this.authService.webServiceURL}/validateToken/${data}`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  previousUrl: any;
  setPreviousUrl(url: any) {
    if (url === '/home' || url?.startsWith('/env/')) {
      this.previousUrl = url;
    }
  }

  getPreviousUrl(): any {
    return this.previousUrl;
  }
}