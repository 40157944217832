import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EnvService } from 'src/app/services/env/env.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';
import { AppComponent } from 'src/app/app.component';
import { UsersDataService } from 'src/app/services/users-data/users-data.service';
import { EnvironmentListService } from 'src/app/services/environmentList/environment-list.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-choose-environment',
  templateUrl: './choose-environment.component.html',
  styleUrls: ['./choose-environment.component.scss']
})
export class ChooseEnvironmentComponent implements OnInit {
  envData: any;
  iterrableData: any;
  initialdata: any;

  checktheme: boolean = false;
  checktheme2: boolean = false;
  checktheme3: boolean = false;
  checktheme4: boolean = false;
  selectedTheme: any;
  themeImageData: any;
  newToken: any
  adminPref: any
  loaderStatus: boolean = false;
  themeImageDataUser: any;
  themeImageDataAdmin: any;
  usergot: boolean = false;
  resDATA: any;
  DecodeToken(token: string): string {
    return jwt_decode(token);
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private env: EnvService,
    private sharedData: SharedService,
    private userdata: UsersDataService,
    private tokenStorage: TokenStorageService,
    private appCOmponent: AppComponent,
    private envservice: EnvironmentListService,
    private activateroute: ActivatedRoute,
    private homeservice: HomeService,

  ) { }

  ngOnInit(): void {
    this.userdata.getAdminThemeData().subscribe((res: any) => {
      this.adminPref = res
      this.userdata.adminThemeAuthType = this.adminPref?.data[0]?.auth_type;
    })
    // if (this.userdata.adminThemeAuthType === 'sso') {
    //   this.http.get(this.authService.webServiceURL + '/auth/callback').subscribe((res: any) => {
    //     this.resDATA = res;
    //     let tokenValue: any = this.DecodeToken(res.data.token);
    //     if (!tokenValue.userData.theme_preference) {
    //       this.userdata.getAdminThemeData().subscribe((res) => {
    //         tokenValue.userData.theme_preference = res.data[0].color_theme;
    //         this.tokenStorage.saveToken(JSON.stringify(this.resDATA.data.token));
    //         this.tokenStorage.saveUser(tokenValue.userData);
    //       })
    //     } else {
    //       this.tokenStorage.saveToken(JSON.stringify(this.resDATA.data.token));
    //       this.tokenStorage.saveUser(tokenValue.userData);
    //     }
    //   });
    // }
    this.activateroute.paramMap.subscribe(params => {
      this.newToken = params.get('newtoken');
    });
    if (this.newToken) {
      try {

        this.ValidateToken()

        this.getEnvironments();
      } catch (error) {
        console.error('Token validation error:', error);
      }
    } else {

      this.getEnvironments();
    }

    this.appCOmponent.settingSlider();
    this.sharedData.setThemeName$.subscribe(message => {
      this.selectedTheme = message;
      this.setDefaultTheme(this.selectedTheme);
    })

    this.authService.loaderStatus.subscribe((data: boolean) => {
      this.loaderStatus = data;

    });
    this.settingSlider();
  }

  changeThemeSettings(themeSeetings) {
    document.documentElement.style.setProperty('--primary-color', themeSeetings.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', themeSeetings.secondaryColor);
    document.documentElement.style.setProperty('--third-color', themeSeetings.thirdColor);
    document.documentElement.style.setProperty('--fourth-color', themeSeetings.fourtFirst);
    document.documentElement.style.setProperty('--fifth-color', themeSeetings.fifthColor);
  }

  setDefaultTheme(selectedTheme: string) {
    let selectedUserDetail = this.tokenStorage.getUser();
    let defaultColorTheme;

    if (!selectedUserDetail?.theme_preference) {
      defaultColorTheme = this.sharedData.getThemeObject(selectedUserDetail?.theme_preference);

    } else {
      defaultColorTheme = this.sharedData.getThemeObject(selectedUserDetail?.theme_preference);
    }
    this.themeImageData = selectedUserDetail?.theme_preference;
    this.changeThemeSettings(defaultColorTheme);
  }
  accessdetails: any;

  getEnvironments() {
    this.authService.showLoader();
    this.env.getEnv().subscribe(
      (res: any) => {
        this.envData = res;
        if (this.envData.status_code == '10000') {
          this.authService.hideLoader();
          this.iterrableData = this.envData.data;
          window.dispatchEvent(new CustomEvent('ngFix'));
        } else {
          this.authService.hideLoader();
        }
      },
      (err: any) => {
        this.authService.hideLoader();
      }
    );
  }
  environmentname: any;
  firstRecordId: any;
  chooseEnvironment(env: any) {
    this.environmentname = env.name;
    this.authService.showLoader();
    this.envservice.getEnvDtl(env.id).subscribe((res) => {
      if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
        this.firstRecordId = res.data[0].id;
        this.authService.hideLoader();
        const dltid = this.firstRecordId;
        const url = `/env/${env.id}/dlt/${dltid}/monitoring`;
        this.router.navigate([url]);
      } else {
        this.authService.hideLoader();
        console.log('No records found or the response data is not an array.');
      }
    }, (e) => {
      this.authService.hideLoader();
      console.log('Error:', e);
    });
  }

  SSOToken: string
  SSOouser: any[] = [];

  ValidateToken() {
    this.homeservice.ssoToken(this.newToken).subscribe((res) => {
      if (res && res.token) {
        this.SSOToken = res.token;
        this.SSOouser = res.user;
        this.tokenStorage.saveToken(JSON.stringify(this.SSOToken))
        this.tokenStorage.saveUser(res.user)
        this.router.navigate(['/home']);
      }
    }, (e) => {
      console.log("error", e)
    })
  }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.homeservice.getToken();
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(req);
  }


  private getHeaders(): HttpHeaders {
    const token = this.homeservice.getToken();

    if (!token) {
      throw new Error('Authentication token not found');
    }

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
  }

  getProtectedData() {
    const headers = this.getHeaders();
    return this.http.get('https://your-api-url/protected-endpoint', { headers });
  }

  settingSlider() {
    let selectedUserDetail = this.tokenStorage.getUser();
    if (selectedUserDetail) {
      this.usergot = true;

      this.themeImageDataUser = selectedUserDetail?.theme_preference;
    }
    else {
      //this.usersDataService.getAdminThemeData().subscribe((res) => {      
      this.themeImageDataAdmin = this.adminPref?.data[0]?.color_theme;
      //})
    }
  }
}
